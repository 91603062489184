@import url("https://fonts.googleapis.com/css2?family=Maven+Pro&family=Montserrat&display=swap");
@import url("../../node_modules/font-awesome/css/font-awesome.min.css");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Montserrat", sans-serif;
}

div,
p,
a,
i {
    font-family: "Maven Pro", sans-serif;
}
