#root {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.clickable {
    cursor: pointer;
}

.nav-link {
    color: #fff !important;
}
.active {
    color: #f39c12 !important;
}

.text-bold {
    font-weight: bolder;
}

.text-shadow {
    text-shadow: rgb(0, 0, 0) 1px 1px 3px;
}

button[disabled] {
    cursor: no-drop;
}

.disable-scrollbars::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.disable-scrollbars {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

/* Header */
.header {
    z-index: 5;
}

.footer {
    z-index: 5;
    margin-top: auto;
}

/* Home screen Video Background */
.content h1 {
    font-weight: 3rem;
    font-size: 60px;
    line-height: 1.2;
    margin-bottom: 15px;
}

.showcase {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    padding: 0 20px;
}

.video-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #3a4052
        url("https://images.pexels.com/photos/943096/pexels-photo-943096.jpeg?cs=srgb&dl=pexels-danny-meneses-943096.jpg&fm=jpg")
        no-repeat center center/cover;
}

.video-container video {
    min-width: 100%;
    min-height: 100%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
}

.video-container:after {
    content: "";
    z-index: 1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    position: relative;
}

.content {
    z-index: 2;
}

.not-found {
    height: 85vh;
}

.not-found-image {
    width: 200px;
}

.vote-icon::before {
    font-size: 2.3rem;
}

.voted {
    color: #3498db;
}
